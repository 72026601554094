import { __assign, __rest } from "tslib";
import { registerNode, BaseGlobal as Global } from '@antv/f6-core';
import { mix } from '@antv/util';
// 五角星shape
registerNode('star', {
  // 自定义节点时的配置
  options: {
    size: 60,
    style: {
      stroke: Global.defaultNode.style.stroke,
      fill: Global.defaultNode.style.fill,
      lineWidth: Global.defaultNode.style.lineWidth
    },
    labelCfg: {
      style: {
        fill: Global.nodeLabel.style.fill,
        fontSize: Global.nodeLabel.style.fontSize
      }
    },
    // 节点上左右上下四个方向上的链接circle配置
    linkPoints: {
      top: false,
      right: false,
      bottom: false,
      left: false,
      // circle的大小
      size: Global.defaultNode.linkPoints.size,
      lineWidth: Global.defaultNode.linkPoints.lineWidth,
      fill: Global.defaultNode.linkPoints.fill,
      stroke: Global.defaultNode.linkPoints.stroke
    },
    // 节点中icon配置
    icon: {
      // 是否显示icon，值为 false 则不渲染icon
      show: false,
      // icon的地址，字符串类型
      img: 'https://gw.alipayobjects.com/zos/bmw-prod/5d015065-8505-4e7a-baec-976f81e3c41d.svg',
      width: 20,
      height: 20
    },
    stateStyles: __assign({}, Global.nodeStateStyles)
  },
  shapeType: 'star',
  // 文本位置
  labelPosition: 'center',
  drawShape: function drawShape(cfg, group) {
    var _a = this.getOptions(cfg).icon,
      icon = _a === void 0 ? {} : _a;
    var style = this.getShapeStyle(cfg);
    var keyShape = group.addShape('path', {
      attrs: style,
      className: "".concat(this.type, "-keyShape"),
      name: "".concat(this.type, "-keyShape"),
      draggable: true
    });
    var w = icon.width,
      h = icon.height,
      show = icon.show,
      text = icon.text;
    if (show) {
      if (text) {
        group.addShape('text', {
          attrs: __assign({
            x: 0,
            y: 0,
            fontSize: 12,
            fill: '#000',
            stroke: '#000',
            textBaseline: 'middle',
            textAlign: 'center'
          }, icon),
          className: "".concat(this.type, "-icon"),
          name: "".concat(this.type, "-icon"),
          draggable: true
        });
      } else {
        group.addShape('image', {
          attrs: __assign({
            x: -w / 2,
            y: -h / 2
          }, icon),
          className: "".concat(this.type, "-icon"),
          name: "".concat(this.type, "-icon"),
          draggable: true
        });
      }
    }
    this.drawLinkPoints(cfg, group);
    return keyShape;
  },
  /**
   * 绘制节点上的LinkPoints
   * @param {Object} cfg data数据配置项
   * @param {Group} group Group实例
   */
  drawLinkPoints: function drawLinkPoints(cfg, group) {
    var _a = this.getOptions(cfg).linkPoints,
      linkPoints = _a === void 0 ? {} : _a;
    var top = linkPoints.top,
      left = linkPoints.left,
      right = linkPoints.right,
      leftBottom = linkPoints.leftBottom,
      rightBottom = linkPoints.rightBottom,
      markSize = linkPoints.size,
      markR = linkPoints.r,
      markStyle = __rest(linkPoints, ["top", "left", "right", "leftBottom", "rightBottom", "size", "r"]);
    var size = this.getSize(cfg);
    var outerR = size[0];
    if (right) {
      // right circle
      // up down left right 四个方向的坐标均不相同
      var x1 = Math.cos((18 + 72 * 0) / 180 * Math.PI) * outerR;
      var y1 = Math.sin((18 + 72 * 0) / 180 * Math.PI) * outerR;
      group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: x1,
          y: -y1,
          r: markSize / 2 || markR || 5
        }),
        className: 'link-point-right',
        name: 'link-point-right'
      });
    }
    if (top) {
      // up down left right 四个方向的坐标均不相同
      var x1 = Math.cos((18 + 72 * 1) / 180 * Math.PI) * outerR;
      var y1 = Math.sin((18 + 72 * 1) / 180 * Math.PI) * outerR;
      // top circle
      group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: x1,
          y: -y1,
          r: markSize / 2 || markR || 5
        }),
        className: 'link-point-top',
        name: 'link-point-top'
      });
    }
    if (left) {
      // up down left right 四个方向的坐标均不相同
      var x1 = Math.cos((18 + 72 * 2) / 180 * Math.PI) * outerR;
      var y1 = Math.sin((18 + 72 * 2) / 180 * Math.PI) * outerR;
      // left circle
      group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: x1,
          y: -y1,
          r: markSize / 2 || markR || 5
        }),
        className: 'link-point-left',
        name: 'link-point-left'
      });
    }
    if (leftBottom) {
      // up down left right 四个方向的坐标均不相同
      var x1 = Math.cos((18 + 72 * 3) / 180 * Math.PI) * outerR;
      var y1 = Math.sin((18 + 72 * 3) / 180 * Math.PI) * outerR;
      // left bottom circle
      group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: x1,
          y: -y1,
          r: markSize / 2 || markR || 5
        }),
        className: 'link-point-left-bottom',
        name: 'link-point-left-bottom'
      });
    }
    if (rightBottom) {
      // up down left right 四个方向的坐标均不相同
      var x1 = Math.cos((18 + 72 * 4) / 180 * Math.PI) * outerR;
      var y1 = Math.sin((18 + 72 * 4) / 180 * Math.PI) * outerR;
      // left bottom circle
      group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: x1,
          y: -y1,
          r: markSize / 2 || markR || 5
        }),
        className: 'link-point-right-bottom',
        name: 'link-point-right-bottom'
      });
    }
  },
  getPath: function getPath(cfg) {
    var size = this.getSize(cfg);
    var outerR = size[0];
    var defaultInnerR = outerR * 3 / 8;
    var innerR = cfg.innerR || defaultInnerR;
    var path = [];
    for (var i = 0; i < 5; i++) {
      var x1 = Math.cos((18 + 72 * i) / 180 * Math.PI) * outerR;
      var y1 = Math.sin((18 + 72 * i) / 180 * Math.PI) * outerR;
      var x2 = Math.cos((54 + 72 * i) / 180 * Math.PI) * innerR;
      var y2 = Math.sin((54 + 72 * i) / 180 * Math.PI) * innerR;
      if (i === 0) {
        path.push(['M', x1, -y1]);
      } else {
        path.push(['L', x1, -y1]);
      }
      path.push(['L', x2, -y2]);
    }
    path.push(['Z']);
    return path;
  },
  /**
   * 获取节点的样式，供基于该节点自定义时使用
   * @param {Object} cfg 节点数据模型
   * @return {Object} 节点的样式
   */
  getShapeStyle: function getShapeStyle(cfg) {
    var defaultStyle = this.getOptions(cfg).style;
    var strokeStyle = {
      stroke: cfg.color
    };
    // 如果设置了color，则覆盖原来默认的 stroke 属性。但 cfg 中但 stroke 属性优先级更高
    var style = mix({}, defaultStyle, strokeStyle);
    var path = this.getPath(cfg);
    var styles = __assign({
      path: path
    }, style);
    return styles;
  },
  update: function update(cfg, item) {
    var group = item.getContainer();
    // 这里不传 cfg 参数是因为 cfg.style 需要最后覆盖样式
    var defaultStyle = this.getOptions({}).style;
    var path = this.getPath(cfg);
    // 下面这些属性需要覆盖默认样式与目前样式，但若在 cfg 中有指定则应该被 cfg 的相应配置覆盖。
    var strokeStyle = {
      stroke: cfg.color,
      path: path
    };
    // 与 getShapeStyle 不同在于，update 时需要获取到当前的 style 进行融合。即新传入的配置项中没有涉及的属性，保留当前的配置。
    var keyShape = item.get('keyShape');
    var style = mix({}, defaultStyle, keyShape.attr(), strokeStyle);
    style = mix(style, cfg.style);
    this.updateShape(cfg, item, style, true);
    this.updateLinkPoints(cfg, group);
  },
  /**
   * 更新linkPoints
   * @param {Object} cfg 节点数据配置项
   * @param {Group} group Item所在的group
   */
  updateLinkPoints: function updateLinkPoints(cfg, group) {
    var defaultLinkPoints = this.getOptions({}).linkPoints;
    var markLeft = group.find(function (element) {
      return element.get('className') === 'link-point-left';
    });
    var markRight = group.find(function (element) {
      return element.get('className') === 'link-point-right';
    });
    var markTop = group.find(function (element) {
      return element.get('className') === 'link-point-top';
    });
    var markLeftBottom = group.find(function (element) {
      return element.get('className') === 'link-point-left-bottom';
    });
    var markRightBottom = group.find(function (element) {
      return element.get('className') === 'link-point-right-bottom';
    });
    var currentLinkPoints = defaultLinkPoints;
    var existLinkPoint = markLeft || markRight || markTop || markLeftBottom || markRightBottom;
    if (existLinkPoint) {
      currentLinkPoints = existLinkPoint.attr();
    }
    var linkPoints = mix({}, currentLinkPoints, cfg.linkPoints);
    var markFill = linkPoints.fill,
      markStroke = linkPoints.stroke,
      borderWidth = linkPoints.lineWidth;
    var markSize = linkPoints.size / 2;
    if (!markSize) markSize = linkPoints.r;
    var _a = cfg.linkPoints ? cfg.linkPoints : {
        left: undefined,
        right: undefined,
        top: undefined,
        leftBottom: undefined,
        rightBottom: undefined
      },
      left = _a.left,
      right = _a.right,
      top = _a.top,
      leftBottom = _a.leftBottom,
      rightBottom = _a.rightBottom;
    var size = this.getSize(cfg);
    var outerR = size[0];
    var styles = {
      r: markSize,
      fill: markFill,
      stroke: markStroke,
      lineWidth: borderWidth
    };
    var x = Math.cos((18 + 72 * 0) / 180 * Math.PI) * outerR;
    var y = Math.sin((18 + 72 * 0) / 180 * Math.PI) * outerR;
    if (markRight) {
      if (!right && right !== undefined) {
        markRight.remove();
      } else {
        markRight.attr(__assign(__assign({}, styles), {
          x: x,
          y: -y
        }));
      }
    } else if (right) {
      group.addShape('circle', {
        attrs: __assign(__assign({}, styles), {
          x: x,
          y: -y
        }),
        className: 'link-point-right',
        name: 'link-point-right',
        isAnchorPoint: true
      });
    }
    x = Math.cos((18 + 72 * 1) / 180 * Math.PI) * outerR;
    y = Math.sin((18 + 72 * 1) / 180 * Math.PI) * outerR;
    if (markTop) {
      if (!top && top !== undefined) {
        markTop.remove();
      } else {
        markTop.attr(__assign(__assign({}, styles), {
          x: x,
          y: -y
        }));
      }
    } else if (top) {
      group.addShape('circle', {
        attrs: __assign(__assign({}, styles), {
          x: x,
          y: -y
        }),
        className: 'link-point-top',
        name: 'link-point-top',
        isAnchorPoint: true
      });
    }
    x = Math.cos((18 + 72 * 2) / 180 * Math.PI) * outerR;
    y = Math.sin((18 + 72 * 2) / 180 * Math.PI) * outerR;
    if (markLeft) {
      if (!left && left !== undefined) {
        markLeft.remove();
      } else {
        markLeft.attr(__assign(__assign({}, styles), {
          x: x,
          y: -y
        }));
      }
    } else if (left) {
      group.addShape('circle', {
        attrs: __assign(__assign({}, styles), {
          x: x,
          y: -y
        }),
        className: 'link-point-left',
        name: 'link-point-left',
        isAnchorPoint: true
      });
    }
    x = Math.cos((18 + 72 * 3) / 180 * Math.PI) * outerR;
    y = Math.sin((18 + 72 * 3) / 180 * Math.PI) * outerR;
    if (markLeftBottom) {
      if (!leftBottom && leftBottom !== undefined) {
        markLeftBottom.remove();
      } else {
        markLeftBottom.attr(__assign(__assign({}, styles), {
          x: x,
          y: -y
        }));
      }
    } else if (leftBottom) {
      group.addShape('circle', {
        attrs: __assign(__assign({}, styles), {
          x: x,
          y: -y
        }),
        className: 'link-point-left-bottom',
        name: 'link-point-left-bottom',
        isAnchorPoint: true
      });
    }
    x = Math.cos((18 + 72 * 4) / 180 * Math.PI) * outerR;
    y = Math.sin((18 + 72 * 4) / 180 * Math.PI) * outerR;
    if (markRightBottom) {
      if (!rightBottom && rightBottom !== undefined) {
        markLeftBottom.remove();
      } else {
        markRightBottom.attr(__assign(__assign({}, styles), {
          x: x,
          y: -y
        }));
      }
    } else if (rightBottom) {
      group.addShape('circle', {
        attrs: __assign(__assign({}, styles), {
          x: x,
          y: -y
        }),
        className: 'link-point-right-bottom',
        name: 'link-point-right-bottom',
        isAnchorPoint: true
      });
    }
  }
}, 'single-node');