import { __assign } from "tslib";
import { registerNode, BaseGlobal as Global, Util } from '@antv/f6-core';
import { deepMix, isNumber } from '@antv/util';
var defaultSubjectColors = Util.defaultSubjectColors;
// 饼图节点
registerNode('donut', {
  // 自定义节点时的配置
  options: {
    size: Global.defaultNode.size,
    style: {
      x: 0,
      y: 0,
      stroke: Global.defaultNode.style.stroke,
      fill: Global.defaultNode.style.fill,
      lineWidth: Global.defaultNode.style.lineWidth
    },
    labelCfg: {
      style: {
        fill: Global.nodeLabel.style.fill,
        fontSize: Global.nodeLabel.style.fontSize
      }
    },
    // 节点上左右上下四个方向上的链接circle配置
    linkPoints: {
      top: false,
      right: false,
      bottom: false,
      left: false,
      // circle的大小
      size: Global.defaultNode.linkPoints.size,
      lineWidth: Global.defaultNode.linkPoints.lineWidth,
      fill: Global.defaultNode.linkPoints.fill,
      stroke: Global.defaultNode.linkPoints.stroke
    },
    // 节点中icon配置
    icon: {
      // 是否显示icon，值为 false 则不渲染icon
      show: false,
      // icon的地址，字符串类型
      img: 'https://gw.alipayobjects.com/zos/bmw-prod/5d015065-8505-4e7a-baec-976f81e3c41d.svg',
      width: 20,
      height: 20
    },
    stateStyles: __assign({}, Global.nodeStateStyles)
  },
  shapeType: 'circle',
  // 文本位置
  labelPosition: 'center',
  drawShape: function drawShape(cfg, group) {
    var _a = this.getOptions(cfg).icon,
      defaultIcon = _a === void 0 ? {} : _a;
    var style = this.getShapeStyle(cfg);
    var icon = deepMix({}, defaultIcon, cfg.icon);
    var keyShape = group.addShape('circle', {
      attrs: style,
      className: "".concat(this.type, "-keyShape"),
      draggable: true,
      name: "".concat(this.type, "-keyShape")
    });
    var width = icon.width,
      height = icon.height,
      show = icon.show,
      text = icon.text;
    if (show) {
      if (text) {
        group.addShape('text', {
          attrs: __assign({
            x: 0,
            y: 0,
            fontSize: 12,
            fill: '#000',
            stroke: '#000',
            textBaseline: 'middle',
            textAlign: 'center'
          }, icon),
          className: "".concat(this.type, "-icon"),
          name: "".concat(this.type, "-icon"),
          draggable: true
        });
      } else {
        group.addShape('image', {
          attrs: __assign({
            x: -width / 2,
            y: -height / 2
          }, icon),
          className: "".concat(this.type, "-icon"),
          name: "".concat(this.type, "-icon"),
          draggable: true
        });
      }
    }
    var donutR = keyShape.attr('r');
    var innerR = 0.6 * donutR; // 甜甜圈的内环半径
    var arcR = (donutR + innerR) / 2; // 内环半径与外环半径的平均值
    var _b = cfg,
      _c = _b.donutAttrs,
      donutAttrs = _c === void 0 ? {} : _c,
      _d = _b.donutColorMap,
      donutColorMap = _d === void 0 ? {} : _d;
    var attrNum = Object.keys(donutAttrs).length;
    if (donutAttrs && attrNum > 1) {
      var attrs_1 = [];
      var totalValue_1 = 0;
      Object.keys(donutAttrs).forEach(function (name) {
        var value = donutAttrs[name] || 0;
        if (!isNumber(value)) return;
        attrs_1.push({
          key: name,
          value: value,
          color: donutColorMap[name]
        });
        totalValue_1 += value;
      });
      if (totalValue_1) {
        var lineWidth_1 = donutR - innerR;
        if (attrNum === 1) {
          group.addShape('circle', {
            attrs: {
              r: arcR,
              x: 0,
              y: 0,
              stroke: attrs_1[0].color || defaultSubjectColors[0],
              lineWidth: lineWidth_1
            },
            name: "fan-shape-0"
          });
          return;
        }
        var arcBegin_1 = [arcR, 0];
        var beginAngle_1 = 0;
        attrs_1.forEach(function (attr, i) {
          var percent = attr.value / totalValue_1;
          if (percent < 0.001) return;
          if (percent > 0.999) percent = 1;
          if (percent === 1) {
            group.addShape('circle', {
              attrs: {
                r: arcR,
                x: 0,
                y: 0,
                stroke: attr.color || defaultSubjectColors[i % defaultSubjectColors.length],
                lineWidth: lineWidth_1
              },
              name: "fan-shape-".concat(i)
            });
            return;
          }
          attr.percent = percent;
          attr.angle = percent * Math.PI * 2;
          attr.beginAgnle = beginAngle_1;
          beginAngle_1 += attr.angle;
          attr.endAngle = beginAngle_1;
          attr.arcBegin = arcBegin_1;
          attr.arcEnd = [arcR * Math.cos(attr.endAngle), -arcR * Math.sin(attr.endAngle)];
          var isBig = attr.angle > Math.PI ? 1 : 0;
          var path = [['M', attr.arcBegin[0], attr.arcBegin[1]], ['A', arcR, arcR, 0, isBig, 0, attr.arcEnd[0], attr.arcEnd[1]], ['L', attr.arcEnd[0], attr.arcEnd[1]]];
          group.addShape('path', {
            attrs: {
              path: path,
              lineWidth: lineWidth_1,
              stroke: attr.color || defaultSubjectColors[i % defaultSubjectColors.length]
            },
            name: "fan-shape-".concat(i)
          });
          arcBegin_1 = attr.arcEnd;
        });
      }
    }
    this.drawLinkPoints(cfg, group);
    return keyShape;
  },
  update: undefined
}, 'circle');