import { __assign, __rest } from "tslib";
import { isFunction, groupBy } from '@antv/util';
import { isNaN, calculationItemsBBox } from '../../util/base';
var LayoutController = /** @class */function () {
  function LayoutController(graph) {
    this.graph = graph;
    this.layoutCfg = graph.get('layout') || {};
    this.layoutType = this.getLayoutType();
    this.layoutMethods = [];
    this.initLayout();
  }
  // eslint-disable-next-line class-methods-use-this
  LayoutController.prototype.initLayout = function () {
    // no data before rendering
  };
  LayoutController.prototype.getLayoutType = function () {
    return this.getLayoutCfgType(this.layoutCfg);
  };
  LayoutController.prototype.getLayoutCfgType = function (layoutCfg) {
    var type = layoutCfg.type;
    // type should be top priority
    if (type) {
      return type;
    }
    var pipes = layoutCfg.pipes;
    if (Array.isArray(pipes)) {
      return pipes.map(function (pipe) {
        return (pipe === null || pipe === void 0 ? void 0 : pipe.type) || '';
      });
    }
    return null;
  };
  LayoutController.prototype.isLayoutTypeSame = function (cfg) {
    var current = this.getLayoutCfgType(cfg);
    // already has pipes
    if (Array.isArray(this.layoutType)) {
      return this.layoutType.every(function (type, index) {
        return type === current[index];
      });
    }
    return (cfg === null || cfg === void 0 ? void 0 : cfg.type) === this.layoutType;
  };
  // 绘制
  LayoutController.prototype.refreshLayout = function () {
    var graph = this.graph;
    if (!graph) return;
    if (graph.get('animate')) {
      graph.positionsAnimate();
    } else {
      graph.refreshPositions();
    }
  };
  // 更换布局
  LayoutController.prototype.changeLayout = function (cfg) {
    this.layoutCfg = cfg;
    this.destoryLayoutMethods();
    this.layout();
  };
  // 更换数据
  LayoutController.prototype.changeData = function () {
    this.destoryLayoutMethods();
    this.layout();
  };
  LayoutController.prototype.destoryLayoutMethods = function () {
    var layoutMethods = this.layoutMethods;
    layoutMethods === null || layoutMethods === void 0 ? void 0 : layoutMethods.forEach(function (layoutMethod) {
      layoutMethod.destroy();
    });
    this.layoutMethods = [];
  };
  // 销毁布局，不能使用 this.destroy，因为 controller 还需要被使用，只是把布局算法销毁
  LayoutController.prototype.destroyLayout = function () {
    var graph = this.graph;
    this.destoryLayoutMethods();
    graph.set('layout', undefined);
    this.layoutCfg = undefined;
    this.layoutType = undefined;
    this.layoutMethods = undefined;
  };
  // 从 this.graph 获取数据
  LayoutController.prototype.setDataFromGraph = function () {
    var nodes = [];
    var hiddenNodes = [];
    var edges = [];
    var hiddenEdges = [];
    var comboEdges = [];
    var combos = [];
    var hiddenCombos = [];
    var nodeItems = this.graph.getNodes();
    var edgeItems = this.graph.getEdges();
    var comboItems = this.graph.getCombos();
    var nodeLength = nodeItems.length;
    for (var i = 0; i < nodeLength; i++) {
      var nodeItem = nodeItems[i];
      if (!nodeItem || nodeItem.destroyed) continue;
      var model = nodeItem.getModel();
      if (!nodeItem.isVisible()) {
        hiddenNodes.push(model);
        continue;
      }
      nodes.push(model);
    }
    var edgeLength = edgeItems.length;
    for (var i = 0; i < edgeLength; i++) {
      var edgeItem = edgeItems[i];
      if (!edgeItem || edgeItem.destroyed) continue;
      var model = edgeItem.getModel();
      if (!edgeItem.isVisible()) {
        hiddenEdges.push(model);
        continue;
      }
      if (!model.isComboEdge) edges.push(model);else comboEdges.push(model);
    }
    var comboLength = comboItems.length;
    for (var i = 0; i < comboLength; i++) {
      var comboItem = comboItems[i];
      if (comboItem.destroyed) continue;
      var model = comboItem.getModel();
      if (!comboItem.isVisible()) {
        hiddenEdges.push(model);
        continue;
      }
      combos.push(model);
    }
    return {
      nodes: nodes,
      hiddenNodes: hiddenNodes,
      edges: edges,
      hiddenEdges: hiddenEdges,
      combos: combos,
      hiddenCombos: hiddenCombos,
      comboEdges: comboEdges
    };
  };
  LayoutController.prototype.reLayoutMethod = function (layoutMethod, layoutCfg) {
    var _this = this;
    return new Promise(function (reslove, reject) {
      var graph = _this.graph;
      var layoutType = layoutCfg === null || layoutCfg === void 0 ? void 0 : layoutCfg.type;
      // 每个布局方法都需要注册
      layoutCfg.onLayoutEnd = function () {
        graph.emit('aftersublayout', {
          type: layoutType
        });
        reslove();
      };
      layoutMethod.init(_this.data);
      if (layoutType === 'force') {
        layoutMethod.ticking = false;
        layoutMethod.forceSimulation.stop();
      }
      graph.emit('beforesublayout', {
        type: layoutType
      });
      layoutMethod.execute();
      if (layoutMethod.isCustomLayout && layoutCfg.onLayoutEnd) layoutCfg.onLayoutEnd();
    });
  };
  // 重新布局
  LayoutController.prototype.relayout = function (reloadData) {
    var _this = this;
    var _a = this,
      graph = _a.graph,
      layoutMethods = _a.layoutMethods,
      layoutCfg = _a.layoutCfg;
    if (reloadData) {
      this.data = this.setDataFromGraph();
      var nodes = this.data.nodes;
      if (!nodes) {
        return false;
      }
      this.initPositions(layoutCfg.center, nodes);
    }
    graph.emit('beforelayout');
    var start = Promise.resolve();
    layoutMethods === null || layoutMethods === void 0 ? void 0 : layoutMethods.forEach(function (layoutMethod, index) {
      var currentCfg = layoutCfg[index];
      start = start.then(function () {
        return _this.reLayoutMethod(layoutMethod, currentCfg);
      });
    });
    start.then(function () {
      if (layoutCfg.onAllLayoutEnd) layoutCfg.onAllLayoutEnd();
    }).catch(function (error) {
      console.warn('relayout failed', error);
    });
  };
  // 筛选参与布局的nodes和edges
  LayoutController.prototype.filterLayoutData = function (data, cfg) {
    var nodes = data.nodes,
      edges = data.edges,
      rest = __rest(data, ["nodes", "edges"]);
    if (!nodes) {
      return data;
    }
    var nodesFilter;
    var edegsFilter;
    if (isFunction(cfg === null || cfg === void 0 ? void 0 : cfg.nodesFilter)) {
      nodesFilter = cfg.nodesFilter;
    } else {
      nodesFilter = function nodesFilter() {
        return true;
      };
    }
    if (isFunction(cfg === null || cfg === void 0 ? void 0 : cfg.edgesFilter)) {
      edegsFilter = cfg.edgesFilter;
    } else {
      var nodesMap_1 = nodes.reduce(function (acc, cur) {
        acc[cur.id] = true;
        return acc;
      }, {});
      edegsFilter = function edegsFilter(edge) {
        return nodesMap_1[edge.source] && nodesMap_1[edge.target];
      };
    }
    return __assign({
      nodes: nodes.filter(nodesFilter),
      edges: edges.filter(edegsFilter)
    }, rest);
  };
  LayoutController.prototype.getLayoutBBox = function (nodes) {
    var graph = this.graph;
    var graphGroupNodes = groupBy(graph.getNodes(), function (n) {
      return n.getModel().layoutOrder;
    });
    var layoutNodes = Object.values(graphGroupNodes).map(function (value) {
      var bbox = calculationItemsBBox(value);
      bbox.size = [bbox.width, bbox.height];
      return bbox;
    });
    var groupNodes = Object.values(groupBy(nodes, 'layoutOrder'));
    return {
      groupNodes: groupNodes,
      layoutNodes: layoutNodes
    };
  };
  // 控制布局动画
  // eslint-disable-next-line class-methods-use-this
  LayoutController.prototype.layoutAnimate = function () {};
  // 将当前节点的平均中心移动到原点
  LayoutController.prototype.moveToZero = function () {
    var graph = this.graph;
    var data = graph.get('data');
    var nodes = data.nodes;
    if (nodes[0].x === undefined || nodes[0].x === null || isNaN(nodes[0].x)) {
      return;
    }
    var meanCenter = [0, 0];
    var nodeLength = nodes.length;
    for (var i = 0; i < nodeLength; i++) {
      var node = nodes[i];
      meanCenter[0] += node.x;
      meanCenter[1] += node.y;
    }
    meanCenter[0] /= nodes.length;
    meanCenter[1] /= nodes.length;
    for (var i = 0; i < nodeLength; i++) {
      var node = nodes[i];
      node.x -= meanCenter[0];
      node.y -= meanCenter[1];
    }
  };
  // 初始化节点到 center 附近
  LayoutController.prototype.initPositions = function (center, nodes) {
    var graph = this.graph;
    if (!nodes) {
      return false;
    }
    var nodeLength = nodes ? nodes.length : 0;
    if (!nodeLength) return;
    var width = graph.get('width') * 0.85;
    var height = graph.get('height') * 0.85;
    var horiNum = Math.ceil(Math.sqrt(nodeLength) * (width / height));
    var vertiNum = Math.ceil(nodeLength / horiNum);
    var horiGap = width / (horiNum - 1);
    var vertiGap = height / (vertiNum - 1);
    if (!isFinite(horiGap) || !horiGap) horiGap = 0;
    if (!isFinite(vertiGap) || !horiGap) vertiGap = 0;
    var beginX = center[0] - width / 2;
    var beginY = center[1] - height / 2;
    var allHavePos = true;
    for (var i = 0; i < nodeLength; i++) {
      var node = nodes[i];
      if (isNaN(node.x)) {
        allHavePos = false;
        node.x = i % horiNum * horiGap + beginX;
      }
      if (isNaN(node.y)) {
        allHavePos = false;
        node.y = Math.floor(i / horiNum) * vertiGap + beginY;
      }
    }
    return allHavePos;
  };
  LayoutController.prototype.destroy = function () {
    this.graph = null;
    this.destoryLayoutMethods();
    this.destroyed = true;
  };
  LayoutController.prototype.onTick = function (timestamp) {
    var _a;
    (_a = this.layoutMethods) === null || _a === void 0 ? void 0 : _a.forEach(function (layoutMethod) {
      var _a;
      (_a = layoutMethod === null || layoutMethod === void 0 ? void 0 : layoutMethod.onTick) === null || _a === void 0 ? void 0 : _a.call(layoutMethod, timestamp);
    });
  };
  return LayoutController;
}();
export default LayoutController;