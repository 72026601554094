export * from '../interface';
// Behavior type file
export var G6Event;
(function (G6Event) {
  // common events
  G6Event["CLICK"] = "click";
  G6Event["DBLCLICK"] = "dblclick";
  G6Event["TAP"] = "tap";
  G6Event["DBLTAP"] = "dbltap";
  G6Event["DRAGSTART"] = "dragstart";
  G6Event["DRAGEND"] = "dragend";
  G6Event["DRAG"] = "drag";
  G6Event["DRAGENTER"] = "dragenter";
  G6Event["DRAGLEAVE"] = "dragleave";
  G6Event["DRAGOVER"] = "dragover";
  G6Event["DRAGOUT"] = "dragout";
  G6Event["DDROP"] = "drop";
  G6Event["PINCHSTART"] = "pinchstart";
  G6Event["PINCHMOVE"] = "pinchmove";
  G6Event["PANSTART"] = "panstart";
  G6Event["PANMOVE"] = "panmove";
  G6Event["PANEND"] = "panend";
  G6Event["PRESS"] = "press";
  G6Event["ACTION_END"] = "actionend";
  // touch events
  G6Event["TOUCHSTART"] = "touchstart";
  G6Event["TOUCHMOVE"] = "touchmove";
  G6Event["TOUCHEND"] = "touchend";
  G6Event["CANVAS_TOUCHSTART"] = "canvas:touchstart";
  G6Event["CANVAS_TOUCHMOVE"] = "canvas:touchmove";
  G6Event["CANVAS_TOUCHEND"] = "canvas:touchend";
  G6Event["NODE_TOUCHSTART"] = "node:touchstart";
  G6Event["NODE_TOUCHMOVE"] = "node:touchmove";
  G6Event["NODE_TOUCHEND"] = "node:touchend";
  G6Event["COMBO_TOUCHSTART"] = "combo:touchstart";
  G6Event["COMBO_TOUCHMOVE"] = "combo:touchmove";
  G6Event["COMBO_TOUCHEND"] = "combo:touchend";
  G6Event["EDGE_TOUCHSTART"] = "edge:touchstart";
  G6Event["EDGE_TOUCHMOVE"] = "edge:touchmove";
  G6Event["EDGE_TOUCHEND"] = "edge:touchend";
  // node events
  G6Event["NODE_CLICK"] = "node:click";
  G6Event["NODE_DBLCLICK"] = "node:dblclick";
  G6Event["NODE_DROP"] = "node:drop";
  G6Event["NODE_DRAGOVER"] = "node:dragover";
  G6Event["NODE_DRAGENTER"] = "node:dragenter";
  G6Event["NODE_DRAGLEAVE"] = "node:dragleave";
  G6Event["NODE_DRAGSTART"] = "node:dragstart";
  G6Event["NODE_DRAG"] = "node:drag";
  G6Event["NODE_DRAGEND"] = "node:dragend";
  G6Event["NODE_TAP"] = "node:tap";
  G6Event["NODE_DBLTAP"] = "node:dbltap";
  G6Event["NODE_PANSTART"] = "node:panstart";
  G6Event["NODE_PANMOVE"] = "node:panmove";
  G6Event["NODE_PANEND"] = "node:panend";
  G6Event["NODE_PRESS"] = "node:press";
  // combo, extends from nodes
  G6Event["COMBO_CLICK"] = "combo:click";
  G6Event["COMBO_DBLCLICK"] = "combo:dblclick";
  G6Event["COMBO_DROP"] = "combo:drop";
  G6Event["COMBO_DRAGOVER"] = "combo:dragover";
  G6Event["COMBO_DRAGENTER"] = "combo:dragenter";
  G6Event["COMBO_DRAGLEAVE"] = "combo:dragleave";
  G6Event["COMBO_DRAGSTART"] = "combo:dragstart";
  G6Event["COMBO_DRAG"] = "combo:drag";
  G6Event["COMBO_DRAGEND"] = "combo:dragend";
  G6Event["COMBO_TAP"] = "combo:tap";
  G6Event["COMBO_DBLTAP"] = "combo:dbltap";
  G6Event["COMBO_PANSTART"] = "combo:panstart";
  G6Event["COMBO_PANMOVE"] = "combo:panmove";
  G6Event["COMBO_PANEND"] = "combo:panend";
  G6Event["COMBO_PRESS"] = "combo:press";
  // edge events
  G6Event["EDGE_CLICK"] = "edge:click";
  G6Event["EDGE_DBLCLICK"] = "edge:dblclick";
  G6Event["EDGE_DROP"] = "edge:drop";
  G6Event["EDGE_DRAGOVER"] = "edge:dragover";
  G6Event["EDGE_DRAGENTER"] = "edge:dragenter";
  G6Event["EDGE_DRAGLEAVE"] = "edge:dragleave";
  G6Event["EDGE_TAP"] = "edge:tap";
  G6Event["EDGE_DBLTAP"] = "edge:dbltap";
  G6Event["EDGE_PRESS"] = "edge:press";
  // canvas events
  G6Event["CANVAS_CLICK"] = "canvas:click";
  G6Event["CANVAS_DBLCLICK"] = "canvas:dblclick";
  G6Event["CANVAS_DROP"] = "canvas:drop";
  G6Event["CANVAS_DRAGENTER"] = "canvas:dragenter";
  G6Event["CANVAS_DRAGLEAVE"] = "canvas:dragleave";
  G6Event["CANVAS_DRAGSTART"] = "canvas:dragstart";
  G6Event["CANVAS_DRAG"] = "canvas:drag";
  G6Event["CANVAS_DRAGEND"] = "canvas:dragend";
  G6Event["CANVAS_TAP"] = "canvas:tap";
  G6Event["CANVAS_DBLTAP"] = "canvas:dbltap";
  G6Event["CANVAS_PANSTART"] = "canvas:panstart";
  G6Event["CANVAS_PANMOVE"] = "canvas:panmove";
  G6Event["CANVAS_PANEND"] = "canvas:panend";
  G6Event["CANVAS_PRESS"] = "canvas:press";
  // timing events
  G6Event["BEFORERENDER"] = "beforerender";
  G6Event["AFTERRENDER"] = "afterrender";
  G6Event["BEFOREADDITEM"] = "beforeadditem";
  G6Event["AFTERADDITEM"] = "afteradditem";
  G6Event["BEFOREREMOVEITEM"] = "beforeremoveitem";
  G6Event["AFTERREMOVEITEM"] = "afterremoveitem";
  G6Event["BEFOREUPDATEITEM"] = "beforeupdateitem";
  G6Event["AFTERUPDATEITEM"] = "afterupdateitem";
  G6Event["BEFOREITEMVISIBILITYCHANGE"] = "beforeitemvisibilitychange";
  G6Event["AFTERITEMVISIBILITYCHANGE"] = "afteritemvisibilitychange";
  G6Event["BEFOREITEMSTATECHANGE"] = "beforeitemstatechange";
  G6Event["AFTERITEMSTATECHANGE"] = "afteritemstatechange";
  G6Event["BEFOREITEMREFRESH"] = "beforeitemrefresh";
  G6Event["AFTERITEMREFRESH"] = "afteritemrefresh";
  G6Event["BEFOREITEMSTATESCLEAR"] = "beforeitemstatesclear";
  G6Event["AFTERITEMSTATESCLEAR"] = "afteritemstatesclear";
  G6Event["BEFOREMODECHANGE"] = "beforemodechange";
  G6Event["AFTERMODECHANGE"] = "aftermodechange";
  G6Event["BEFORELAYOUT"] = "beforelayout";
  G6Event["AFTERLAYOUT"] = "afterlayout";
  G6Event["BEFORECREATEEDGE"] = "beforecreateedge";
  G6Event["AFTERCREATEEDGE"] = "aftercreateedge";
  G6Event["BEFOREGRAPHREFRESHPOSITION"] = "beforegraphrefreshposition";
  G6Event["AFTERGRAPHREFRESHPOSITION"] = "aftergraphrefreshposition";
  G6Event["BEFOREGRAPHREFRESH"] = "beforegraphrefresh";
  G6Event["AFTERGRAPHREFRESH"] = "aftergraphrefresh";
  G6Event["BEFOREANIMATE"] = "beforeanimate";
  G6Event["AFTERANIMATE"] = "afteranimate";
  G6Event["BEFOREPAINT"] = "beforepaint";
  G6Event["AFTERPAINT"] = "afterpaint";
  G6Event["BEFORECOLLAPSEEXPANDCOMBO"] = "beforecollapseexpandcombo";
  G6Event["AFTERCOLLAPSEEXPANDCOMBO"] = "aftercollapseexpandcombo";
  G6Event["GRAPHSTATECHANGE"] = "graphstatechange";
  G6Event["AFTERACTIVATERELATIONS"] = "afteractivaterelations";
  G6Event["NODESELECTCHANGE"] = "nodeselectchange";
  G6Event["TOOLTIPCHANGE"] = "tooltipchange";
  G6Event["WHEELZOOM"] = "wheelzoom";
  G6Event["VIEWPORTCHANGE"] = "viewportchange";
  G6Event["DRAGNODEEND"] = "dragnodeend";
  G6Event["STACKCHANGE"] = "stackchange";
})(G6Event || (G6Event = {}));