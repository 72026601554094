import { __assign } from "tslib";
import { mix } from '@antv/util';
import Global from '../../global';
import Shape from '../shape';
Shape.registerNode('simple-rect', {
  // 自定义节点时的配置
  options: {
    size: [100, 30],
    style: {
      radius: 0,
      stroke: Global.defaultNode.style.stroke,
      fill: Global.defaultNode.style.fill,
      lineWidth: Global.defaultNode.style.lineWidth
    },
    // 文本样式配置
    labelCfg: {
      style: {
        fill: Global.nodeLabel.style.fill,
        fontSize: Global.nodeLabel.style.fontSize
      }
    },
    // 连接点，默认为左右
    // anchorPoints: [{ x: 0, y: 0.5 }, { x: 1, y: 0.5 }]
    anchorPoints: [[0, 0.5], [1, 0.5]],
    stateStyles: __assign({}, Global.nodeStateStyles)
  },
  shapeType: 'simple-rect',
  labelPosition: 'center',
  drawShape: function drawShape(cfg, group) {
    var style = this.getShapeStyle(cfg);
    var keyShape = group.addShape('rect', {
      attrs: style,
      className: "".concat(this.type, "-keyShape"),
      name: "".concat(this.type, "-keyShape"),
      draggable: true
    });
    return keyShape;
  },
  /**
   * 获取节点的样式，供基于该节点自定义时使用
   * @param {Object} cfg 节点数据模型
   * @return {Object} 节点的样式
   */
  getShapeStyle: function getShapeStyle(cfg) {
    var defaultStyle = this.getOptions(cfg).style;
    var strokeStyle = {
      stroke: cfg.color
    };
    // 如果设置了color，则覆盖默认的stroke属性
    var style = mix({}, defaultStyle, strokeStyle);
    var size = this.getSize(cfg);
    var width = style.width || size[0];
    var height = style.height || size[1];
    var styles = __assign({
      x: -width / 2,
      y: -height / 2,
      width: width,
      height: height
    }, style);
    return styles;
  },
  update: function update(cfg, item) {
    var group = item.getContainer();
    // 这里不传 cfg 参数是因为 cfg.style 需要最后覆盖样式
    var defaultStyle = this.getOptions({}).style;
    var size = this.getSize(cfg);
    var keyShape = item.get('keyShape');
    if (!cfg.size) {
      size[0] = keyShape.attr('width') || defaultStyle.width;
      size[1] = keyShape.attr('height') || defaultStyle.height;
    }
    // 下面这些属性需要覆盖默认样式与目前样式，但若在 cfg 中有指定则应该被 cfg 的相应配置覆盖。
    var strokeStyle = {
      stroke: cfg.color,
      x: -size[0] / 2,
      y: -size[1] / 2,
      width: size[0],
      height: size[1]
    };
    // 与 getShapeStyle 不同在于，update 时需要获取到当前的 style 进行融合。即新传入的配置项中没有涉及的属性，保留当前的配置。
    var style = mix({}, defaultStyle, keyShape.attr(), strokeStyle);
    style = mix(style, cfg.style);
    this.updateShape(cfg, item, style, false);
  }
}, 'single-node');