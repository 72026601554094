function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
/**
 * @fileOverview 从xml建立自定义Node，包含update
 * @author xuzhi.mxz@antfin.com
 */
import { __assign, __rest } from "tslib";
import { get } from '@antv/util';
import { getTextSize } from '../util/graphic';
/**
 * 一种更宽松的JSON 解析，如果遇到不符合规范的字段会直接转为字符串
 * @param text json 内容
 */
function looseJSONParse(text) {
  if (typeof text !== 'string') {
    return text;
  }
  var safeParse = function safeParse(str) {
    if (typeof str !== 'string') {
      return str;
    }
    try {
      return JSON.parse(str.trim());
    } catch (e) {
      return str.trim();
    }
  };
  var firstAttempt = safeParse(text);
  if (typeof firstAttempt !== 'string') {
    return firstAttempt;
  }
  var tail = function tail(arr) {
    return arr[arr.length - 1];
  };
  var str = text.trim();
  var objectStack = [];
  var syntaxStack = [];
  var isLastPair = function isLastPair() {
    var syntaxes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      syntaxes[_i] = arguments[_i];
    }
    return syntaxes.some(function (syntax) {
      return tail(syntaxStack) === syntax;
    });
  };
  var getValueStore = function getValueStore() {
    return tail(objectStack);
  };
  var rst = null;
  var i = 0;
  var temp = '';
  while (i < str.length) {
    var nowChar = str[i];
    var isInString = isLastPair('"', "'");
    if (!isInString && !nowChar.trim()) {
      i += 1;
      continue;
    }
    var isLastTranslate = str[i - 1] === '\\';
    var isInObject = isLastPair('}');
    var isInArray = isLastPair(']');
    var isWaitingValue = isLastPair(',');
    var tempArr = getValueStore();
    if (isInString) {
      if (tail(syntaxStack) === nowChar && !isLastTranslate) {
        syntaxStack.pop();
        var value = safeParse(temp);
        tempArr.push(value);
        rst = value;
        temp = '';
      } else {
        temp += nowChar;
      }
    } else if (isInArray && nowChar === ',') {
      if (temp) {
        tempArr.push(safeParse(temp));
        temp = '';
      }
    } else if (isInObject && nowChar === ':') {
      syntaxStack.push(',');
      if (temp) {
        tempArr.push(temp);
        temp = '';
      }
    } else if (isWaitingValue && nowChar === ',') {
      if (temp) {
        tempArr.push(safeParse(temp));
        temp = '';
      }
      syntaxStack.pop();
    } else if (nowChar === '}' && (isInObject || isWaitingValue)) {
      if (temp) {
        tempArr.push(safeParse(temp));
        temp = '';
      }
      if (isWaitingValue) {
        syntaxStack.pop();
      }
      var obj = {};
      for (var c = 1; c < tempArr.length; c += 2) {
        obj[tempArr[c - 1]] = tempArr[c];
      }
      objectStack.pop();
      if (objectStack.length) {
        tail(objectStack).push(obj);
      }
      syntaxStack.pop();
      rst = obj;
    } else if (nowChar === ']' && isInArray) {
      if (temp) {
        tempArr.push(safeParse(temp));
        temp = '';
      }
      objectStack.pop();
      if (objectStack.length) {
        tail(objectStack).push(tempArr);
      }
      syntaxStack.pop();
      rst = tempArr;
    } else if (nowChar === '{') {
      objectStack.push([]);
      syntaxStack.push('}');
    } else if (nowChar === '[') {
      objectStack.push([]);
      syntaxStack.push(']');
    } else if (nowChar === '"') {
      syntaxStack.push('"');
    } else if (nowChar === "'") {
      syntaxStack.push("'");
    } else {
      temp += nowChar;
    }
    i += 1;
  }
  return rst || temp;
}
var keyConvert = function keyConvert(str) {
  return str.split('-').reduce(function (a, b) {
    return a + b.charAt(0).toUpperCase() + b.slice(1);
  });
};
/**
 * 简单的一个{{}}模板渲染，不包含任何复杂语法
 * @param xml
 */
export var xmlDataRenderer = function xmlDataRenderer(xml) {
  return function (data) {
    var len = xml.length;
    var arr = [];
    var i = 0;
    var tmp = '';
    while (i < len) {
      if (xml[i] === '{' && xml[i + 1] === '{') {
        arr.push(tmp);
        tmp = '';
        i += 2;
      } else if (xml[i] === '}' && xml[i + 1] === '}') {
        if (arr.length) {
          var last = arr.pop();
          tmp = get(data, tmp, last.endsWith('=') ? "\"{".concat(tmp, "}\"") : tmp);
          arr.push(last + tmp);
        }
        i += 2;
        tmp = '';
      } else {
        tmp += xml[i];
        i += 1;
      }
    }
    arr.push(tmp);
    return arr.map(function (e, index) {
      return arr[index - 1] && arr[index - 1].endsWith('=') ? "\"{".concat(e, "}\"") : e;
    }).join('');
  };
};
/**
 * 解析XML，并转化为相应的JSON结构
 * @param xml xml解析后的节点
 */
export function parseXML(xml, cfg) {
  var attrs = {};
  var keys = xml.getAttributeNames && xml.getAttributeNames() || [];
  var children = xml.children && Array.from(xml.children).map(function (e) {
    return parseXML(e, cfg);
  });
  var rst = {};
  var tagName = xml.tagName ? xml.tagName.toLowerCase() : 'group';
  if (tagName === 'text') {
    attrs.text = xml.innerText;
  }
  rst.type = tagName;
  if (tagName === 'img') {
    rst.type = 'image';
  }
  Array.from(keys).forEach(function (k) {
    var key = keyConvert(k);
    var val = xml.getAttribute(k);
    try {
      if (key === 'style' || key === 'attrs') {
        var style = looseJSONParse(val);
        attrs = __assign(__assign({}, attrs), style);
      } else {
        rst[key] = looseJSONParse(val);
      }
    } catch (e) {
      if (key === 'style') {
        throw e;
      }
      rst[key] = val;
    }
  });
  rst.attrs = attrs;
  if (cfg && cfg.style && rst.name && _typeof(cfg.style[rst.name]) === 'object') {
    rst.attrs = __assign(__assign({}, rst.attrs), cfg.style[rst.name]);
  }
  if (cfg && cfg.style && rst.keyshape) {
    rst.attrs = __assign(__assign({}, rst.attrs), cfg.style);
  }
  if (children.length) {
    rst.children = children;
  }
  return rst;
}
/**
 * 根据偏移量和内部节点最终的bounding box来得出该shape最终的bbox
 */
export function getBBox(node, offset, chilrenBBox) {
  var _a = node.attrs,
    attrs = _a === void 0 ? {} : _a;
  var bbox = {
    x: offset.x || 0,
    y: offset.y || 0,
    width: chilrenBBox.width || 0,
    height: chilrenBBox.height || 0
  };
  var shapeHeight, shapeWidth;
  switch (node.type) {
    case 'maker':
    case 'circle':
      if (attrs.r) {
        shapeWidth = 2 * attrs.r;
        shapeHeight = 2 * attrs.r;
      }
      break;
    case 'text':
      if (attrs.text) {
        shapeWidth = getTextSize(attrs.text, attrs.fontSize || 12)[0];
        shapeHeight = 16;
        bbox.y += shapeHeight;
        bbox.height = shapeHeight;
        bbox.width = shapeWidth;
        node.attrs = __assign({
          fontSize: 12,
          fill: '#000'
        }, attrs);
      }
      break;
    default:
      if (attrs.width) {
        shapeWidth = attrs.width;
      }
      if (attrs.height) {
        shapeHeight = attrs.height;
      }
  }
  if (shapeHeight >= 0) {
    bbox.height = shapeHeight;
  }
  if (shapeWidth >= 0) {
    bbox.width = shapeWidth;
  }
  if (attrs.marginTop) {
    bbox.y += attrs.marginTop;
  }
  if (attrs.marginLeft) {
    bbox.x += attrs.marginLeft;
  }
  return bbox;
}
/**
 * 把从xml计算出的结构填上位置信息，补全attrs
 * @param target
 * @param lastOffset
 */
export function generateTarget(target, lastOffset) {
  var _a;
  if (lastOffset === void 0) {
    lastOffset = {
      x: 0,
      y: 0
    };
  }
  var defaultBbox = __assign({
    x: 0,
    y: 0,
    width: 0,
    height: 0
  }, lastOffset);
  if ((_a = target.children) === null || _a === void 0 ? void 0 : _a.length) {
    var _b = target.attrs,
      attrs = _b === void 0 ? {} : _b;
    var marginTop = attrs.marginTop;
    var offset = __assign({}, lastOffset);
    if (marginTop) {
      offset.y += marginTop;
    }
    for (var index = 0; index < target.children.length; index++) {
      target.children[index].attrs.key = "".concat(attrs.key || 'root', " -").concat(index, " ");
      var node = generateTarget(target.children[index], offset);
      if (node.bbox) {
        var bbox = node.bbox;
        if (node.attrs.next === 'inline') {
          offset.x += node.bbox.width;
        } else {
          offset.y += node.bbox.height;
        }
        if (bbox.width + bbox.x > defaultBbox.width) {
          defaultBbox.width = bbox.width + bbox.x;
        }
        if (bbox.height + bbox.y > defaultBbox.height) {
          defaultBbox.height = bbox.height + bbox.y;
        }
      }
    }
  }
  target.bbox = getBBox(target, lastOffset, defaultBbox);
  target.attrs = __assign(__assign({}, target.attrs), target.bbox);
  return target;
}
/**
 * 对比前后两个最终计算出来的node，并对比出最小改动,
 * 动作： 'add' 添加节点 ｜ ’delete‘ 删除节点 ｜ ’change‘ 改变节点attrs ｜ 'restructure' 重构节点
 * @param nowTarget
 * @param formerTarget
 */
export function compareTwoTarget(nowTarget, formerTarget) {
  var _a, _b, _c, _d;
  var type = (nowTarget || {}).type;
  var key = ((formerTarget === null || formerTarget === void 0 ? void 0 : formerTarget.attrs) || {}).key;
  if (key && nowTarget) {
    nowTarget.attrs.key = key;
  }
  if (!nowTarget && formerTarget) {
    return {
      action: 'delete',
      val: formerTarget,
      type: type,
      key: key
    };
  }
  if (nowTarget && !formerTarget) {
    return {
      action: 'add',
      val: nowTarget,
      type: type
    };
  }
  if (!nowTarget && !formerTarget) {
    return {
      action: 'same',
      type: type
    };
  }
  var children = [];
  if (((_a = nowTarget.children) === null || _a === void 0 ? void 0 : _a.length) > 0 || ((_b = formerTarget.children) === null || _b === void 0 ? void 0 : _b.length) > 0) {
    var length_1 = Math.max((_c = nowTarget.children) === null || _c === void 0 ? void 0 : _c.length, (_d = formerTarget.children) === null || _d === void 0 ? void 0 : _d.length);
    var formerChilren = formerTarget.children || [];
    var nowChilren = nowTarget.children || [];
    for (var index = 0; index < length_1; index += 1) {
      children.push(compareTwoTarget(nowChilren[index], formerChilren[index]));
    }
  }
  var formerKeys = Object.keys(formerTarget.attrs);
  var nowKeys = Object.keys(nowTarget.attrs);
  if (formerTarget.type !== nowTarget.type) {
    return {
      action: 'restructure',
      nowTarget: nowTarget,
      formerTarget: formerTarget,
      key: key,
      children: children
    };
  }
  if (formerKeys.filter(function (e) {
    return e !== 'children';
  }).some(function (e) {
    return nowTarget.attrs[e] !== formerTarget.attrs[e] || !nowKeys.includes(e);
  })) {
    return {
      action: 'change',
      val: nowTarget,
      children: children,
      type: type,
      key: key
    };
  }
  return {
    action: 'same',
    children: children,
    type: type,
    key: key
  };
}
/**
 * 根据xml或者返回xml的函数构建自定义节点的结构
 * @param gen
 */
export function createNodeFromXML(gen) {
  var structures = {};
  var compileXML = function compileXML(cfg) {
    var rawStr = typeof gen === 'function' ? gen(cfg) : gen;
    var target = xmlDataRenderer(rawStr)(cfg);
    // TODO 这里需要一个patch #16
    var xmlParser = document.createElement('div');
    xmlParser.innerHTML = target;
    var xml = xmlParser.children[0];
    var result = generateTarget(parseXML(xml, cfg));
    xmlParser.remove();
    return result;
  };
  return {
    draw: function draw(cfg, group) {
      var resultTarget = compileXML(cfg);
      var keyshape = group;
      var renderTarget = function renderTarget(target) {
        var _a = target.attrs,
          attrs = _a === void 0 ? {} : _a,
          bbox = target.bbox,
          type = target.type,
          children = target.children,
          rest = __rest(target, ["attrs", "bbox", "type", "children"]);
        if (target.type !== 'group') {
          var shape = group.addShape(target.type, __assign({
            attrs: attrs,
            origin: {
              bbox: bbox,
              type: type,
              children: children
            }
          }, rest));
          if (target.keyshape) {
            keyshape = shape;
          }
        }
        if (target.children) {
          target.children.forEach(function (n) {
            return renderTarget(n);
          });
        }
      };
      renderTarget(resultTarget);
      structures[cfg.id] = [resultTarget];
      return keyshape;
    },
    update: function update(cfg, node) {
      if (!structures[cfg.id]) {
        structures[cfg.id] = [];
      }
      var container = node.getContainer();
      var children = container.get('children');
      var newTarget = compileXML(cfg);
      var lastTarget = structures[cfg.id].pop();
      var diffResult = compareTwoTarget(newTarget, lastTarget);
      var addShape = function addShape(shape) {
        var _a;
        if (shape.type !== 'group') {
          container.addShape(shape.type, {
            attrs: shape.attrs
          });
        }
        if ((_a = shape.children) === null || _a === void 0 ? void 0 : _a.length) {
          shape.children.map(function (e) {
            return addShape(e);
          });
        }
      };
      var delShape = function delShape(shape) {
        var _a;
        var targetShape = children.find(function (e) {
          return e.attrs.key === shape.attrs.key;
        });
        if (targetShape) {
          container.removeChild(targetShape);
        }
        if ((_a = shape.children) === null || _a === void 0 ? void 0 : _a.length) {
          shape.children.map(function (e) {
            return delShape(e);
          });
        }
      };
      var updateTarget = function updateTarget(target) {
        var key = target.key;
        if (target.type !== 'group') {
          var targetShape = children.find(function (e) {
            return e.attrs.key === key;
          });
          switch (target.action) {
            case 'change':
              if (targetShape) {
                var originAttr = target.val.keyshape ? node.getOriginStyle() : {};
                targetShape.attr(__assign(__assign({}, originAttr), target.val.attrs));
              }
              break;
            case 'add':
              addShape(target.val);
              break;
            case 'delete':
              delShape(target.val);
              break;
            case 'restructure':
              delShape(target.formerTarget);
              addShape(target.nowTarget);
              break;
            default:
              break;
          }
        }
        if (target.children) {
          target.children.forEach(function (n) {
            return updateTarget(n);
          });
        }
      };
      updateTarget(diffResult);
      structures[cfg.id].push(newTarget);
    },
    getAnchorPoints: function getAnchorPoints() {
      return [[0, 0.5], [1, 0.5], [0.5, 1], [0.5, 0]];
    }
  };
}